.shopping-cart {
  @import "../colors";
  background-color: $color-grey-10;
  padding-bottom: 22px;
}

.shopping-cart__row {
  display: flex;

  @import "../colors";
  border-bottom: 2px solid $color-white;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-as-row--below}") {
    display: block;
  }
}

.shopping-cart__row--header {
  @import "../colors";
  background-color: $color-green;
  color: $color-white;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.shopping-cart__cell {
  @import "../colors";
  border-right: 2px solid $color-white;

  overflow: hidden;

  &:last-of-type {
    border-right: none;
  }

  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-as-row--below}") {
    border-right: none;
  }

  a {
    font-size: 16px;
  }
}

.shopping-cart__pin-container, .shopping-cart__cell {
  padding: 15px 22px;
}

.shopping-cart__cell--column1 {
  flex: 1;
}

.shopping-cart__cell--column2 {
  width: 70px;
  text-align: right;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-as-row--below}") {
    display: block;
    width: initial;

    padding-top: 0;
  }
}

.shopping-cart__summary {
  display: flex;
  justify-content: space-between;
  padding: 15px 22px;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-as-row--below}") {
    display: block;
  }
}

.shopping-cart__summary-grand-total {
  font-size: 20px;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
}

.shopping-cart__summary-grand-total-label {
  margin-right: 15px;
}

.shopping-cart__last-change, .shopping-cart__summary-hint {
  font-size: 14px;
}

.shopping-cart__last-change {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-as-row--below}") {
    margin-bottom: 20px;
  }
}

.shopping-cart__learning-unit-service {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-end;

  > div {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  i {
    @import "../colors";
    color: $color-grey-70;
    font-size: 18px;
  }

  @import "~include-media/dist/include-media";
  @import "../breakpoints";
  @include media("<#{$breakpoint_shopping-cart-unit-service-block--below}") {
    display: block;

    > div {
      margin-right:0;
      margin-top: 10px;
    }
  }
}

.shopping-cart__product-already-active, .shopping-cart__product-by-pin {
  font-style: italic;
}

.shopping-cart__product-already-active {
  @import "../colors";
  color: $color-red;
}

.shopping-cart__product-by-pin {
  @import "../colors";
  color: $color-grey-70;
}

.shopping-cart__pin-container {
  display: flex;
  justify-content: center;

  text-align: center;

  margin-top: 2px;

  input {
    width: 60%;
    height: 40px;
  }

  .button {
    font-size: 24px;
    padding: 5px 20px;
  }
}

.shopping-cart__pay {
  margin: 22px 0;

  display: flex;
  justify-content: space-between;
}
