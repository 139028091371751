

table {
  @import "../colors";
  border-collapse: collapse;
  table-layout: fixed;
  width: initial;
  margin: 18px 0;
  overflow: hidden;

  p {
    margin: 0;
  }

  td,
  th {
    min-width: 1em;
    border: 2px solid $color-grey-70;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    > * {
      margin-bottom: 0;
    }
    ul, ol {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 30px;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
  }
}
