.colored--blue {
  @import "../colors";
  color: $color-blue;

}

.colored--green {
  @import "../colors";
  color: $color-green;
}
