*,
select,
input {
  font-family: "Profile", sans-serif;
  letter-spacing: 0;
}

h1,
h2,
h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;

  @import "colors";
  color: $color-blue;

  hyphens: auto; // auto thesaurus
}

h1,
h2 {
  font-size: 25px;
}

h3 {
  font-size: 18px;
}

.left-and-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-and-right--evade-on-small-screens {
  flex-wrap: wrap;
}

.heading--distanced-content {
  margin-bottom: 33px;
}

.heading--distanced-content-small {
  margin-bottom: 18px;
}

.heading--distanced-predecessor {
  margin-top: 20px;
}

.heading--accent {
  @import "colors";
  color: $color-green;
}

.heading--inverted {
  @import "colors";
  color: $color-white;
}

p {
  font-size: 18px;
  line-height: 23px;
}

.paragraph--lacy {
  font-size: 16px;
  line-height: 23px;
}

input[type="submit"],
button,
.button {
  @import "colors";
  background-color: $color-green;
  &, &:visited /* visited, if the button is an <a>-tag */ {
    color: $color-white;
  }
  border: none;

  cursor: pointer;

  padding: 15px 27px;
  font-size: 16px;
  text-decoration: none;

  text-transform: uppercase;

  &:hover {
    background-color: darken($color-green, 10%);
  }
}

// CSS-Reset for links
a.button {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
}

button:focus,
.button:focus {
  outline: none;
}

.button--light {
  @import "colors";
  background-color: $color-grey-50;

  &:hover {
    background-color: darken($color-grey-50, 10%);
  }
}

input[type="submit"].button--blue, .button--blue {
  @import "colors";
  background-color: $color-blue;

  &:hover {
    background-color: darken($color-blue, 10%);
  }
}

.button--disabled {
  @import "colors";
  background-color: $color-grey-50;
  cursor: not-allowed;

  &:hover {
    background-color: $color-grey-50;
  }
}

.button--full-width {
  width: 100%;
}

.button--icon-only {
  padding: 7px 7px;
  font-size: 30px;
  line-height: 100%;
}

a {
  @import "colors";
  &, &:visited { color: $color-green; }
  text-decoration: none;
}

.a--with-decoration {
  text-decoration: underline;
}

.a--accent {
  @import "colors";
  color: $color-green;
}

.span--accent {
  @import "colors";
  color: $color-green;
}

figure {
  margin: 0;
}

table {
  width: 100%;

  border-collapse: collapse;

  td {
    padding: 7px 0;
  }

  td.td--no-pading {
    padding: 0;
  }
}

.table--line-like {
  border-collapse: collapse;

  td {
    @import "colors";
    $_border-def: 1px solid $color-black;
    border-top: $_border-def;
    border-bottom: $_border-def;
  }

  tr {
    > td:first-of-type {
      font-weight: bold;
      padding-right: 30px;
    }
  }
}

.table--line-like-green {
  tr {
    > td:first-of-type {
      @import "colors";
      color: $color-green;
    }
  }
}

.ul--custom-symbol {
  list-style-type: none;
  padding-left: 10px;
  > li {
    position: relative;
    padding-left: 20px;

    > i {
      position:absolute;
      margin-right: 5px;
      left: 0;
    }
  }
}

hr {
  opacity: 0.2;

  @import "colors";
  border-color: $color-black;
  border-top: 0;
}

video {
  max-width: 100%;
}

iframe.clickmeeting {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

body.empty {
  overflow: hidden;
}

.label--highlight {
  @import "./colors";
  color: $color-red;
}


