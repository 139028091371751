.info-box {
  @import "../colors";
  background-color: $color-blue;
  color: $color-white;
  padding: 10px 15px;
  margin: 15px 0;

  > * {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  li > p {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  a {
    color: $color-white;
    text-decoration: underline;
  }
}

.info-box--warning {
  @import "../colors";
  background-color: $color-green;
}

.info-box--advice {
  @import "../colors";
  background-color: $color-blue;
}

.info-box--simple-table {
  @import "../colors";
  background-color: transparent;
  color: $color-black;
  border: 2px solid $color-grey-70;
}
