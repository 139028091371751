.payed__errors, .payed__success {
  margin-top: 30px;
}

.payed__success {
  @import "../colors";
  color: $color-green;
}

.payed__error {
  @import "../colors";
  color: $color-red;
}

.payed__transaction-box {
  margin-top: 50px;
}
