.loader {
  @import "../colors";

  $_loader_margin: 10px;
  $_loader_size: 30px;
  $_loader_thickness: 5px;
  $_loader_primary_color: $color-blue;
  $_loader_secondary_color: transparentize($color-blue, 0.8);

  &, &:after {
    border-radius: 50%;
    width: $_loader_size;
    height: $_loader_size;

  }

  margin: $_loader_margin;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: $_loader_thickness solid  $_loader_secondary_color;
  border-right: $_loader_thickness solid $_loader_secondary_color;
  border-bottom: $_loader_thickness solid $_loader_secondary_color;
  border-left: $_loader_thickness solid $_loader_primary_color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// can be used to place
.loader__container {
  display: flex;
  align-items: center;

  .loader {
    margin-left: 0;
  }
}

.loader__description {
  flex: 1;
}
