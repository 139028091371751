.rss {
  width: 100%; // IE-fix
}

.rss__figure {
  margin: 0;
  margin-bottom: 19px;
}

.rss__date {
  font-weight: bold;
  margin-bottom: 10px;
}

.rss__title {
  &,
  & > a {
    @extend .content-box__banner-title;
    text-decoration: none;
  }
}

