.course__media {
  @import "../colors";
  color: $color-grey-20;
  font-size: 20px;

  white-space: nowrap;
}

.course__media--active {
  @import "../colors";
  color: $color-green;
}
