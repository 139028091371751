@font-face {
	font-family: "icon-collection";
	font-display: block;
	src: url("/packs/webfont.icon-collection.a94e7c0d3969ad230f70be6052e42fb2.eot?#iefix") format("embedded-opentype"),
url("/packs/webfont.icon-collection.cee7719160c556ff3a8aa3ea7f746899.woff") format("woff"),
url("/packs/webfont.icon-collection.d488a77c6833056a14b363be4b78e213.woff2") format("woff2"),
url("/packs/webfont.icon-collection.2ed2832cf6bf537e94f7878056b162ff.ttf") format("truetype"),
url("/packs/webfont.icon-collection.cddcab442677b96493962c6d39ac11e4.svg#icon-collection") format("svg");
}

.icon-collection {
	line-height: 1;
}

.icon-collection:before {
	font-family: icon-collection !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ic-minus_o:before {
	content: "\e001";
}
.ic-gluehbirne-live-webinare:before {
	content: "\f101";
}
.ic-gluehbirne-online-kurse:before {
	content: "\f102";
}
.ic-gluehbirne-podcasts:before {
	content: "\f103";
}
.ic-gluehbirne-seminarunterlagen:before {
	content: "\f104";
}
.ic-gluehbirne-videos:before {
	content: "\f105";
}
.ic-star-filled:before {
	content: "\f106";
}
.ic-search-solid:before {
	content: "\f107";
}
.ic-chevron-down-solid:before {
	content: "\f108";
}
.ic-menu-solid:before {
	content: "\f109";
}
.ic-calendar:before {
	content: "\f10a";
}
.ic-certificate:before {
	content: "\f10b";
}
.ic-clock:before {
	content: "\f10c";
}
.ic-plus_o:before {
	content: "\f10d";
}
.ic-comment:before {
	content: "\f10e";
}
.ic-file:before {
	content: "\f10f";
}
.ic-mobile:before {
	content: "\f110";
}
.ic-play:before {
	content: "\f111";
}
.ic-volume:before {
	content: "\f112";
}
.ic-check:before {
	content: "\f113";
}
.ic-forward-solid:before {
	content: "\f114";
}
.ic-backward-solid:before {
	content: "\f115";
}
.ic-delete:before {
	content: "\f116";
}
.ic-receipt:before {
	content: "\f117";
}
.ic-monitor:before {
	content: "\f118";
}
.ic-download:before {
	content: "\f119";
}
.ic-fullscreen-close:before {
	content: "\f11a";
}
.ic-fullscreen-open:before {
	content: "\f11b";
}
.ic-link:before {
	content: "\f11c";
}
