.paginator .pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  .page {
    &.current {
      @import "../colors";
      color: $color-black;
      font-weight: bold;
    }

    margin: 0 5px;
  }

  .first, .prev, .last, .next {
    @import "../colors";
    color: $color-green;
  }

  .prev, .next {
    flex: 1;
  }

  .last, .next {
    text-align: right;
  }

  .prev {
    margin-left: 10px;
  }

  .last {
    margin-left: 10px;
  }
}
