.course-navigation {
  display: flex;
  justify-content: flex-end;

  > a {
    margin-left: 12px;
  }
}

.course-progress {
  margin-bottom: 33px;
}

.course-slide__content {
  table {
    td, th {
      @import "../colors";
      border: 1px solid $color-blue;
      padding: 2px 10px;
    }
  }

  img {
    width: initial;
  }

  div, p {
    line-height: 23px;
    font-size: 16px;
  }

  h1, h2, h3, h4 {
    margin-bottom: 20px;
  }

  figure {
    margin: 18px 0;
  }

  @import "./course-slide-table";
  @import "../components/info-box";


  // legacy stylings
  .paragraph-source12 {
    margin-bottom: 14px;
  }

  div.warning-source {
    @import "../colors";
    background-color: $color-green;
    color: $color-white;
    padding: 1px 15px;
    margin: 15px 0;
  }

  .advice-source {
    @import "../colors";
    margin: 15px 0;

    .advice-source-title {
      background-color: $color-blue;
      color: $color-white;
      display: inline-block;
      padding: 5px;
    }

    .advice-source-content {
      border: 1px solid $color-blue;
      padding: 15px 15px;
    }
  }
}
