input, select {
  border: none;
  padding: 7px 13px;
  box-sizing: border-box;

  border-radius: 0; // Safari CSS-reset

  &:focus {
    outline: none;
  }
}

select {
  @import "colors";
  background-color: $color-white;
  border-radius: 0;
  -webkit-appearance: none; // Safari CSS-reset
}

// Used for the chevron on the right
.select-container {
  position: relative;

  > select { height: 100%; } // Safari CSS-reset

  .select-container__chevron {
    @import "colors";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: $color-blue;
    background-color: $color-white;
    font-size: 20px;
    width: 44px;
    text-align: center;

    padding-top: 7px;
    border-left: 2px solid $color-blue;
    pointer-events: none; // pass events to underlying element

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
    }
  }
}

.select-container--small {
  margin-bottom: 0;

  > select {
    @import "colors";
    color: $color-green;
    padding-top: 0;
    padding-bottom: 0;
  }

  .select-container__chevron {
    border: none;
    width: 10px;

    @import "colors";
    color: $color-green;

    font-size: 10px;
  }
}

.input--with-border {
  @import "colors";
  border: 1px solid $color-black;
}

.input--small {
  @import "colors";
  padding: 5px 13px;
}

.input--big, .select--big {
  padding: 12px 13px;
}

.input-container-with-icon {
  position: relative;

  .input-container-with-icon__icon {
    position: absolute;
    right: 15px;
    top: 7px;

    cursor: pointer;

    // remove button styling
    padding: 0;
    margin: 0;
    background-color: initial;

    @import "colors";
    color: $color-black;
  }

  input {
    padding-right: 32px + 13px;
  }
}

.field {
  label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
  }

  input[type="checkbox"], input[type="checkbox"]+label {
    display: inline;
  }

  margin-bottom: 17px;
}

.field--full-width {
  width: 100%;

  input, label, select {
    box-sizing: border-box;
    width: 100%;
  }
}

.field--required::after {
  @import "colors";
  content: '*';
  color: $color-green;
}
