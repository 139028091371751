.attestation-question {
  @import "../colors";
  background-color: $color-green-hue;

  font-size: 16px;

  p { margin: 0; font-size: 16px; }
}

.attestation-question--neutral {
  @import "../colors";
  background-color: $color-grey-20;
}

.attestation-question--red {
  @import "../colors";
  background-color: $color-red;
}

.attestation-question__text {
  font-weight: bold;

  margin-bottom: 15px;
}

.attestation-question__description {
  margin-bottom: 15px;
}

.attestation-question__answer {
  display: flex;
  align-items: center;

  > input {
    margin-right: 7px;
  }

  margin-bottom: 7px;
}

.attestation-question__success-message {
  @import "../colors";
  color: $color-green;
}
