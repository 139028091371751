/**************************\
  Basic Modal Styles
  from:
\**************************/

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}

.modal__container {
  background-color: #fff;
  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;

  p {
    font-size: 16px;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}

.modal__close {
  @import "../colors";
  background: transparent;
  color: $color-black;
  border: 0;

  &:hover {
    @import "../colors";
    background-color: $color-green;
    color: $color-white;
  }
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
}

.modal__footer {
  display: flex;
  justify-content: space-between;
}

.modal__btn {
  @import "../colors";
  padding: 15px 27px;
  background-color: #e6e6e6;
  color: $color-black;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  text-transform: uppercase;

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }
}

.modal__btn:hover {
  background-color: darken($color: #e6e6e6, $amount: 10%);
}

.modal__btn-primary {
  @import "../colors";
  color: $color-white;
  background-color: $color-green;

  &:hover {
    background-color: darken($color: $color-green, $amount: 10%);
  }
}
