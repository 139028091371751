.live-webinar-box__columns {
  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_live-webinare-box-no-columns--below}") {
    flex-direction: column;
  }
}

.live-webinar-box__avatar {
  width: 145px;
  flex-shrink: 0;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_live-webinare-box-no-columns--below}") {
    margin-top: 30px;
    order: 99;
  }
}

.live-webinar-box__heading {
  font-size: 28px;
}

.live-webinar-box__consent-area {
  @import "../colors";
  color: $color-black;

  margin-bottom: 15px;

  display: flex;
  align-items: center;

  justify-content: center;

  input {
    margin-right: 4px;
  }

  a {
    &, &:visited { color: $color-green; }
  }
}

.live-webinar-box__consent-area--below {
  margin-top: 15px;
  margin-bottom: 0;
}

.live-webinar-box__sign-up-area {
  margin-top: 18px;
  padding: 17px;
  text-align: center;

  @import "../colors";
  background-color: $color-white;
}

.live-webinar-box__sign-up-area-action {
  @import "../colors";
  background-color: $color-green;
  padding: 20px;

  display: inline-block;
}

.live-webinar-box__referent {
  font-weight: bold;
}

.live-webinar-box__item-list {
  min-height: 180px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

   // RESPONSIVE
   @import "~include-media/dist/include-media";
   @import "../breakpoints";

   @include media("<#{$breakpoint_live-webinare-box-no-columns--below}") {
     min-height: initial;
     margin-top: 30px;
   }
}

.live-webinar-box__item-list--centered {
  justify-content: center;
}

.live-webinar-box__list-item {
  font-size: 20px;

  // margin for every item except the last one
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }

  > i {
    font-size: 23px;
    margin-right: 15px;
  }

  a {
    &, &:visited, &:hover {
      @import "../colors";
      color: $color-white;
    }
  }
}

.live-webinar-referent__columns {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_live-webinare-referent-no-columns--below}") {
    flex-direction: column;
  }
}

.live-webinar-referent__avatar {
  flex-shrink: 0;
  &, img { width: 115px; }
}

.live-webinar-referent__referent {
  @import "../colors";
  color: $color-green;
  font-size: 18px;
  font-weight: bold;
}
