// External includes
@import "normalize.css/normalize.css";
@import "application/fonts";
@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";
@import "vidstack/styles/defaults.css"; // vidstack video-player
@import "vidstack/styles/community-skin/video.css"; // vidstack video-player

// global stylings
@import "application/global";
@import "application/form";

// BEM-components
@import "application/components/indent-box";
@import "application/components/content-box";
@import "application/components/quick-tile";
@import "application/components/search-tool";
@import "application/components/rss";
@import "application/components/accordion";
@import "application/components/action-bar";
@import "application/components/paginator";
@import "application/components/sorter";
@import "application/components/toc";
@import "application/components/progress-bar";
@import "application/components/attestation-question";
@import "application/components/flash-box";
@import "application/components/shopping-cart";
@import "application/components/transaction-box";
@import "application/components/model-field";
@import "application/components/loader";
@import "application/components/general-tile";
@import "application/components/course";
@import "application/components/certifications-cme";
@import "application/components/colored";
@import "application/components/description-banner";
@import "application/components/modal";

// one-shot stuff (stylings only for one place)
@import "application/one_shots/layout";
@import "application/one_shots/startseite";
@import "application/one_shots/login-panel";
@import "application/one_shots/live-webinar";
@import "application/one_shots/course-slide";
@import "application/one_shots/podcast";
@import "application/one_shots/payed";
@import "application/one_shots/feedback-form";
@import "application/one_shots/user-profile";
@import "application/one_shots/cookielayer";
@import "application/one_shots/glide";
@import "application/one_shots/seminars";

// animations
@import "application/animations/shake";
