.feedback-form {
  input, textarea {
    @import "../colors";
    border: 1px solid $color-black;
  }

  textarea {
    width: 100%;
    resize: vertical;

    padding: 7px 13px;

    box-sizing: border-box;
  }

  input[type="submit"] {
    border: none;
  }

  label {
    margin-bottom: 3px;
  }

  .model-field {
    margin-bottom: 10px;
  }
}

.feedback-form__errors {
  @import "../colors";
  color: $color-red;
  margin-bottom: 20px;

  &.surgeontalk_feedback-form__errors {
    color: yellow;
  }
}
