.flash-box {
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
}

.flash-box--without-breadcrumbs:first-of-type {
  margin-top: 0;
}

.flash-box--notice {
  @import "../colors";
  background-color: $color-green-hue;
}

.flash-box--alert {
  @import "../colors";
  background-color: $color-red;
}
