.startseite-quick-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_quicktiles-center--below}") {
    justify-content: center;
  }
}

.startseite-teasers {
  display: flex;
  justify-content: space-between;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_startseite-teasers-single-column--below}") {
    display: block;
  }
}

.startseite-teaser {
  width: calc(50% - 15px);
  margin: 30px 0;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_startseite-teasers-single-column--below}") {
    width: 100%;
  }

  a {
    &, * {
      color: initial;
    }
  }
}

.startseite-teaser-title {
  font-weight: bold;
  font-size: 18px;

  margin-top: 10px;
  min-height: 40px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_startseite-teasers-single-column--below}") {
    height: auto;
  }
}

.startseite-teaser-authors {
  @import "../colors";
  color: $color-green;

  margin-top: 22px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_startseite-teasers-single-column--below}") {
    margin-top: 10px;
  }
}
