
video {
  width: 100%;
}

.position-input {
  &,
  &:focus {
    @import "~stylesheets/application/colors";
    outline: none;
    margin: 0;
    padding: 0;

    width: 20px;
    border: none;
    background-color: transparent;
    color: $color-grey-70;
    margin-right: 2px;
    border-radius: none;

    text-align: right;
  }
}

// hide arrows on number field
/* Chrome, Safari, Edge, Opera */
input.position-input::-webkit-outer-spin-button,
input.position-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.position-input {
  -moz-appearance: textfield;
}

.slide-presentation__slides {
  @import "~stylesheets/application/colors";
  border: 7px solid $color-grey-20;
  border-radius: 5px 5px 0 0;

  line-height: 0;

  img {
    width: 100%;
  }

  position: relative;

  &,
  .slide-presentation__fullscreen-image,
  img {
    user-select: none;
  }

  .slide-presentation__fullscreen-image {
    display: none;
  }

  @media print {
    // hide content in print screen
    display: none;
  }
}

.slide-presentation__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: transparent;

  z-index: 999;
}

.slide-presentation__fullscreen-image {
  height: 100%;
  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.slide-presentation__controls {
  @import "~stylesheets/application/colors";
  background-color: $color-grey-10;

  padding: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-presentation__control-group {
  display: flex;
}

.slide-presentation__fullscreen-button {
  @import "~stylesheets/application/colors";
  background-color: $color-grey-70;
  color: $color-white;

  width: 23px;
  height: 23px;

  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 15px;
  }

  cursor: pointer;
}

.slide-presentation__progress {
  @import "~stylesheets/application/colors";
  color: $color-grey-70;

  display: flex;
  align-items: center;

  font-size: 12px;
  margin-left: 10px;
}

.vue__slide-presentation:focus {
  outline: none;
}

.vue__slide-presentation:fullscreen {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  .slide-presentation__slides {
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      display: none;
    }

    video {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    .slide-presentation__fullscreen-image {
      display: block;
    }
  }

  .slide-presentation__controls {
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 54px;
  }
}
