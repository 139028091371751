.seminars-box {
  display: flex;
  justify-content: space-between;
}

.seminars-box__status {
  @import "../colors";

  display: flex;
  .seminar-status {
    color: $color-blue;
    font-size: 25px;
    padding: 10px;

    display: flex;
    align-items: center;

    &.seminar-status--disabled {
      color: $color-grey-70;
    }
  }
}

.seminars-box__pre-header {
  display: flex;
  align-items: flex-end;

  > i {
    font-size: 35px;
    margin-right: 5px;
  }

  margin-bottom: 10px;
}

.seminars-box__content {
  @import "../colors";
  background-color: $color-white;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  padding: 45px 25px;

  > div {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.seminars-box__attachments {
  h4 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.seminars-box__attachment {
  margin-bottom: 5px;
  font-size: 16px;
}

.seminars-box__attachment-type {
  @import "../colors";
  color: $color-grey-50;
  font-size: 12px;
  margin-left: 5px;
}


