.accordion {
  // the input that is used to save the state of the accordion
  // it should be hidden
  input.accordion__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  // Exchange icon if the input
  input.accordion__input:checked {
    + .accordion__label {
      i::before {
        // this is the fixed codepoint for minus_o, see /app/javascript/fonts/icon-collection/icon-collection.font.js
        content: "\e001";
      }
    }
  }

  input.accordion__input:checked {
    ~ .accordion__content {
      display: block;
    }
  }

  margin-bottom: 30px;
}

.accordion__content {
  display: none;
}

.accordion__label {
  display: flex;
  align-items: center;
  padding: 20px 31px;

  cursor: pointer;

  @import "../colors";
  background-color: $color-blue;

  &,
  * {
    color: $color-white;
    font-size: 25px;
  }

  i {
    margin-right: 18px;
  }
}
