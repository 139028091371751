.content-box {
  @import "../colors";
  background-color: $color-grey-10;
  color: $color-black;

  // width: 100%;

  padding: 22px;
  margin-bottom: 30px;

  img {
    max-width: 100%;
  }
}

.content-box--green {
  @import "../colors";
  background-color: $color-green;
  color: $color-white;

  a, a:visited {
    &, * {
      color: $color-blue;
    }
  }

  input[type="submit"],
  button,
  .button {
    @import "../colors";
    &, &:visited /* visited, if the button is an <a>-tag */ {
      color: $color-white;
    }
  }
}

.content-box--green-hue {
  @import "../colors";
  background-color: $color-green-hue;
}

.content-box--distanced {
  margin-top: 25px;
  margin-bottom: 25px;
}

.content-box--small-bottom-distance {
  margin-bottom: 10px;
}

.content-box h1 {
  margin-bottom: 8px;
}

.content-box__banner-title {
  @import "../colors";
  color: $color-green;

  font-size: 18px;
  font-weight: bold;

  margin: 15px 0;
}

.content-box__columns {
  display: flex;

  > div {
    margin-right: 27px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.content-box__columns--with-wrap-on-mobile {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_content-box_mobile--below}") {
    flex-wrap: wrap;
  }
}

.content-box__column--order-last-on-mobile {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_content-box_mobile--below}") {
    order: 99;
  }
}


