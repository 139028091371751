.my_cookielayer {
  p {
    line-height: 1.4 !important;
  }

  .button {
    font-size: 14px;

    cursor: pointer;
  }
}
