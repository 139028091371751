@font-face {
  font-family: "Profile";
  font-display: swap;
  src: url('../fonts/profile/web/profile-web.eot');
  src: url('../fonts/profile/web/profile-web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/profile/web/profile-web.woff') format('woff'),
       url('../fonts/profile/web/profile-comp.ttf') format('truetype');
}

@font-face {
  font-family: "Profile";
  font-display: swap;
  src: url('../fonts/profile/web/ProfileWeb-Medium.eot');
  src: url('../fonts/profile/web/ProfileWeb-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/profile/web/ProfileWeb-Medium.woff') format('woff'),
       url('../fonts/profile/web/ProfileComp-Medium.ttf') format('truetype');
  font-weight: bold;
}
