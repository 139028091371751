.general-tile__columns {
  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    flex-wrap: wrap;
  }
}

.general-tile__avatar {
  flex-shrink: 0;
  img {
    width: 120px;
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    order: 99;
    width: 100%;
  }

  margin-top: 20px;

  display: flex;
  flex-direction: column;
}

.content-box__columns > div.general-tile__avatar {// RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    margin-right: 0;
    margin-left: 10px;
  }
}

.general-tile__description {
  flex: 1;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  h1,
  h2 {
    font-size: 22px;

    @include media("<#{$breakpoint_general-tile_no-columns--below}") {
      font-size: 5vw;
    }
  }

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    font-size: 4vw;
  }
}

.general-tile__icon {
  font-size: 35px;
  padding: 0 0;
}

.general-tile__label-container {
  display: flex;
  align-items: center;
}

.general-tile__top-labels {
  display: flex;
  flex-wrap: wrap;
}

.general-tile__top-label {
  @import "../colors";
  border-right: 1px solid $color-black;
  padding: 0 7px;

  &:last-of-type {
    border-right: none;
  }

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    font-size: 4vw;
  }
}

.general-tile__referent {
  margin-top: 20px;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    font-size: 4vw;
  }
}

.general-tile--bold {
  font-weight: bold;
}

.general-tile--hint {
  @import "../colors";
  color: $color-blue;

  font-size: 18px;
  margin-top: 18px;
}

.general-tile__short-description {
  font-weight: bold;
  &, p { font-size: 16px; }
  line-height: 23px;

  margin-top: 15px;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    font-size: 4vw;
    line-height: 5vw;
  }
}

.general-tile__category-box {
  @import "../colors";
  background-color: $color-blue;
  color: $color-white;

  padding: 9px;
  font-size: 14px;
  line-height: 20px;

  margin-top: 35px;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    font-size: 4vw;
  }
}

.general-tile__table {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_general-tile_no-columns--below}") {
    margin-top: 15px;

    font-size: 4vw;
  }
}
