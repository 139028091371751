.search-tool {
  margin-top: 25px;
  margin-bottom: 55px;
}

.search-tool__header {
  display: flex;
  justify-content: flex-start;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_search-tool--full-width--below}") {
    flex-wrap: wrap;
  }
}

a.search-tool__header-item, a.search-tool__header-item:hover {
  // fix link as container
  @import "../colors";
  color: $color-blue;
}

.search-tool__header-item {
  @import "../colors";

  padding: 10px 21px;
  font-size: 25px;
  font-weight: bold;

  text-transform: uppercase;

  cursor: pointer;

  color: $color-blue;
  border: 1px solid $color-blue;
  min-width: 180px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_search-tool--full-width--below}") {
    width: 100%;
  }
}

.search-tool__header-item--active {
  @import "../colors";

  background-color: $color-blue;
  color: $color-white;


  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_search-tool--full-width--below}") {
    order: 99; // move active item to last position
  }
}

.search-tool__body {
  @import "../colors";
  background-color: $color-blue;
  color: $color-white;

  padding: 40px 22px;
}

.search-tool-simple__inputs {
  display: flex;

  > div {
    flex-basis: 50%;
    margin-right: 17px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_search-tool--full-width--below}") {
    display: block;
  }
}
