// "ul" reset
ul.toc {
  &, ul, li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: none;
  }
}

.toc {
  @import "../colors";

  .toc__entry { // needs to be nested because of reset above
    display: flex;
    align-items: center;

    &, a, a:visited { color: $color-black; }

    padding: 9px 0;
    font-size: 14px;

    $_border-definition: 1px solid $color-blue;
    border-top: $_border-definition;
    &:last-of-type { border-bottom: $_border-definition; }
  }

  .toc__entry--active {
    background-color: $color-green-hue;
  }

  .toc__entry--as_checkbox_list {
    border: none;
    padding: 3px;
    font-size: 16px;

    &:last-of-type {
      border: none;
    }
  }
}

.toc__entry-indent {
  width: 19px;
}

.toc__entry-symbol {
  @import "../colors";
  border: 1px solid $color-blue;
  width: 10px;
  height: 10px;
  margin-right: 9px;
}

.toc__entry-symbol--as_checkbox_list {
  border: none;
}

.toc__entry-indent, .toc__entry-symbol {
  flex-shrink: 0; // force width, even if there is less space
}

.toc__entry-symbol--visited {
  @import "../colors";
  background-color: $color-blue;
}

.toc__entry-symbol--active {
  @import "../colors";
  border: 1px solid $color-green;
}
