.transaction-box__revokation {
  @import "../colors";
  color: $color-red;
  margin-top: 20px;
}
.transaction-box__on-demand-booking {
  @import "../colors";
  color: $color-blue;
  margin-top: 20px;
}

.transaction-box__fields {
  display: flex;
  flex-wrap: wrap;
}

.transaction-box__field {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.transaction-box__field-name {
  // @import "../colors";
  // color: $color-blue;
  font-size: 12px;
}

.transaction-box__field-value {
  // @import "../colors";
  // color: $color-blue;
}

.transaction-box {
  h4 {
    margin-bottom: 0;
  }

  ul {
    // list-style-type: none;
    margin: 0;
    padding-left: 17px;
  }

  li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.transaction-box__data {
  // flex: 0.8;
}

.transaction-box__action {
  margin-top: 10px;
}

.transaction-box__action--details {
  margin-top: 20px;
}
