
.vue__payment-manager {
  .payment-manager__grand-total {
    display: flex;
    justify-content: space-between;

    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    margin-top: 20px;
    font-size: 22px;
  }

  .payment-manager__vat {
    font-size: 14px;
    text-align: right;
  }

  .payment-manager__payments-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .payment-manager__payment-selector {
    @import "~stylesheets/application/colors";
    border: 1px solid $color-black;
    padding: 15px;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;

    width: 120px;
    overflow: hidden;
  }

  .payment-manager__payment-selector--active {
    @import "~stylesheets/application/colors";
    border: 1px solid $color-blue;
    background-color: $color-blue;
    color: $color-white;
  }

  .payment-manager__address {
    display: flex;

    input {
      margin-right: 15px;
      margin-top: 5px;
    }

    padding: 15px 10px;
    margin-bottom: 15px;

    @import "~stylesheets/application/colors";
    background-color: $color-grey-10;
  }

  .payment-manager__address-kind {
    font-weight: bold;
    margin-bottom: 5px;
  }

  margin-bottom: 20px;
}
