ul.glide__slides {
  margin-top: 0;
  margin-bottom: 0;
}

.glide__slide {
  text-align: center;
}

button.glide__arrow:hover {
  background-color: transparent;
}

.glide__bullet {
  @import "../colors";
  background-color: transparentize($color-blue, 0.75);
}

.glide__bullet--active, .glide__bullet:focus, .glide__bullet:hover {
  @import "../colors";
  background-color: $color-blue;
}

.glide__bullet:focus, .glide__bullet:hover {
  @import "../colors";
  border-color: darken($color-blue, 0.2);
}

.glide__bullets {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_glider-bullets-bottom--below}") {
    bottom: 20px;
  }

  @include media("<#{$breakpoint_glider-bullets-hide--below}") {
    display: none;
  }
}
