.indent-box {
  width: 100%;

  > .indent-box__inner {
    @import "../settings";

    margin-left: auto;
    margin-right: auto;

    max-width: $layout_max_width;
  }

  &.indent-box--blue {
    @import "../colors";

    background-color: $color-blue;
    color: $color-white;
  }

  &.indent-box--green {
    @import "../colors";

    background-color: $color-green;
    color: $color-white;
  }
}
