.certifications-cme {
  @import "../colors";
  background-color: $color-grey-10;
  .certification-cme:nth-child(even) {
    background-color: $color-white;
  }
}

.certification-cme {
  padding: 15px;
}

.certification-cme__bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  flex-wrap: wrap;
}

.certification-cme__bottom-right, .certification-cme__bottom-left {
  margin-top: 15px;
}

.certification-cme__title {
  font-size: 18px;
}

.certification-cme__error {
  @import "../colors";
  color: $color-red;
}
