.progress-bar {
  @import "../colors";
  background-color: $color-green;
  padding: 18px 30px;
}

.progress-bar__border-box {
  display: flex;

  @import "../colors";
  border: 1px solid $color-white;
  padding: 1px;

  height: 30px;

  position: relative;
}

// IE-Fallback:
.progress-bar__progress {
  @import "../colors";
  background-color: $color-white;

  // width will be set directly on the element
}

.progress-bar__fallback-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  text-align: center;
  font-size: 16px;

  // vertical center
  top: 50%;
  transform: translateY(-50%);
}
// IE-Fallback end

// Hide, and show only if supported
.progress-bar__front-label,
.progress-bar__back-label {
  display: none;
}

@supports (clip-path: inset(0 0 0 50%)) {
  .progress-bar__fallback-label {
    display: none;
  }

  .progress-bar__front-label,
  .progress-bar__back-label {
    display: initial;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    text-align: center;
    font-size: 16px;

    // vertical center
    top: 50%;
    transform: translateY(-50%);
  }

  .progress-bar__front-label {
    @import "../colors";
    color: $color-green;
  }

  .progress-bar__back-label {
    @import "../colors";
    color: $color-white;

    // clip-path: inset(0 0 0 percentage); will be set directly on the element
  }
}
