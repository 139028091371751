$color-blue: #0A285F;
$color-green: #75B7AC;
$color-green-hue: #DAEEED;
$color-black: #000000;
$color-white: #FFFFFF;
$color-red: #F79791;

// the higher the number, the lighter the tone
$color-grey-10: #F5F5F5;
$color-grey-20: #CDCDCD;
$color-grey-50: #B4B4B4;
$color-grey-70: #949494;
