// enable css-grid prefixing for IE
/* autoprefixer grid: autoplace */

.layout-header {
  padding-top: 22px;
  padding-bottom: 29px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-navigation-off-canvas-menu--below}") {
    padding-top: 5px;
    padding-bottom: 0;
  }
}

.layout-header-quicknav {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 16px;

  margin-bottom: 12px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-padded--below}") {
    padding-right: 20px;
  }

  @include media("<#{$breakpoint_layout-navigation-off-canvas-menu--below}") {
    display: none;
  }
}

.layout-header-quicknav-quicklinks {
  display: flex;

  margin-right: 17px;

  > div, a {
    $_padding-lr: 5px;
    padding-left: $_padding-lr;
    padding-right: $_padding-lr;
    padding-bottom: 2px;
    cursor: pointer;

    height: 15px;

    @import "../colors";
    border-left: 1px solid $color-black;
    &:nth-child(1) {
      border-left: none;
    }
  }
}

.layout-header-quicknav-quicklink {
  text-transform: uppercase;
}

a, a:visited {
  &.layout-header-quicknav-quicklink {
    @import "../colors";
    color: $color-black;
  }

}

.layout-header-quick-search {
  input {
    width: 191px;
  }
}

.layout-header-mainstrip {
  display: flex;
  align-items: center;
  margin-top: 12px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-navigation-two-lines--below}") {
    display: block;
  }
}

.layout-header-navbar {
  @import "../colors";

  background-color: $color-green;
  &, a *, a:visited * { color: $color-white; }
  font-size: 18px;

  margin-left: 59px;
  margin-bottom: 0; // CSS-Reset

  width: 100%;
  height: 53px;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  // style specifically ul tag
  @at-root #{selector-append(ul, &)} {
    list-style-type: none;
    text-align: center;
    padding-left: 0; // CSS-Reset for ul

    > li, > a {
      cursor: pointer;
      height: 100%;
      padding: 0 5px;

      // base size is the content
      flex-basis: content;
      // additional size is distributed equally
      flex-grow: 1;

      &:hover {
        background-color: darken($color-green, 10%);
      }

      &.active * {
        @import "../colors";
        color: $color-blue
      }

      &.layout-header-navbar__item--off-canvas {
        display: none;
      }

      // align spans vertically to center
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-navigation-two-lines--below}") {
    margin-left: 0;
  }

  @include media("<#{$breakpoint_layout-navigation-off-canvas-menu--below}") {
    display: none;
  }
}

.layout-header-navbar__item {
  text-transform: uppercase;
}

#open-menu:target {
  overflow: hidden; // directly attached to body

  .layout-header-navbar--off-canvas {
    display: block;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 999; // on top of the glider

    width: auto;
    height: auto;

    margin: 0;
    padding: 20px;

    > li, > a {
      display: block;

      height: auto;
      width: 100%;
      margin: 15px 0;
      padding: 10px 0;

      @import "../colors";
      background-color: $color-green;
    }

    .layout-header-navbar__item.layout-header-navbar__item--off-canvas {
      display: block;


      @import "../colors";
      background-color: $color-white;
      color: $color-black;
    }


    @import "../colors";
    background-color: $color-white;
  }
}

.layout-header-navbar--mobile {
  display: none;
  height: 42px;
  margin-left: 0;

  justify-content: space-between;

  // style specifically ul tag
  @at-root #{selector-append(ul, &)} {
    > li, > a {
      flex-grow: 0;
      margin: 0 10px;

      &.layout-header-navbar__off-canvas-search-field {
        padding: 0;

        .layout-header-navbar__off-canvas-search-form {
          &, > div {
            &, input {
              height: 100%;
            }

            button {
              height: 100%;
              top: 0;
            }
          }
        }

        .layout-header-navbar__off-canvas-search-form {
          input {
            font-size: 16px;
          }
        }

        > i {
          display: none;
        }
      }

      &.layout-header-navbar__off-canvas-menu-button {
        font-size: 30px;
      }
    }
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-navigation-off-canvas-menu--below}") {
    display: flex;
  }
}

.layout-header-logo {
  img {
    width: 341px;
    height: 63px;

    // RESPONSIVE
    @import "~include-media/dist/include-media";
    @import "../breakpoints";

    @include media("<#{$breakpoint_layout-navigation-off-canvas-menu--below}") {
      height: 46px;
      width: 245px;
    }
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-padded--below}") {
    padding-left: 20px;
  }
}

.layout-presentation-strip {
  max-width: 100%;
  &,
  img {
    max-height: 200px;
    max-width: 100%;
    //object-fit: cover; // Does not work for IE, TODO
  }
}

.layout-main {
  padding-bottom: 40px;
}

.layout-sidebar {
  max-width: 100%; // IE-fix
}

.layout-content-main-strip {
  padding: 35px 0;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  // RESPONSIVE
  @include media(
    ">=#{$breakpoint_sidebar-two-columns--above}",
    "<#{$breakpoint_sidebar-on-the-right--above}"
  ) {
    .layout-sidebar {
      display: flex;

      > div {
        flex-basis: 50%;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @include media(">=#{$breakpoint_sidebar-on-the-right--above}") {
    display: grid;
    // instead of "auto" we use minmax(min-content, 1fr), because of IE,
    // see: https://css-tricks.com/css-grid-in-ie-debunking-common-ie-grid-misconceptions/#article-header-id-5
    grid-template-columns: 1fr 371px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "main aside-top"
      "main aside-bottom";
    grid-column-gap: 35px;

    .layout-main {
      grid-area: main;
    }

    .layout-sidebar {
      justify-self: end;
    }

    .layout-sidebar--primary {
      grid-area: aside-top;
    }
    .layout-sidebar--secondary {
      grid-area: aside-bottom;
    }
  }

  @include media("<=#{$breakpoint_layout-padded--below}") {
    padding: 35px 20px;
  }
}

.layout-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 299px;
}

.layout-footer-navbar {
  margin-bottom: 11px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    $_padding-lr: 5px;
    padding-left: $_padding-lr;
    padding-right: $_padding-lr;
    cursor: pointer;

    font-size: 17px;
    line-height: 1;

    @import "../colors";
    border-left: 1px solid $color-white;

    &:nth-child(1) {
      border-left: none;
    }
  }
}

.layout-footer-navbar__item {
  text-transform: uppercase;
  &, a {
    @import "../colors";
    color: $color-white;
  }
}

.layout-footer-logo {
  margin-top: 11px;
  img {
    width: 398px;
    height: 73px;

    // RESPONSIVE
    @import "~include-media/dist/include-media";
    @import "../breakpoints";

    @include media("<=#{$breakpoint_layout-footer-small--below}") {
      width: 80vw;
      height: initial;
    }
  }
}

.layout-breadcrumbs {
  padding-top: 10px;

  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<=#{$breakpoint_layout-padded--below}") {
    padding-left: 20px;
    padding-right: 20px;
  }
}
