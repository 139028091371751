.user-profile__table-like {
  margin: 20px 0;
}

.user-profile__table-row {
  display: flex;
  margin: 5px 0;
}

.user-profile__table-cell--key {
  width: 200px;
}

.user-profile__change-action {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.user-profile__change-action-button {
  margin-right: 20px;
  flex-shrink: 0;

}

.user-profile__change-action-description {
  @import "../colors";
  color: $color-red;

}
