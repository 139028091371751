a.quick-tile, .quick-tile {
  @import "../colors";
  background-color: $color-green;
  &, &:visited { color: $color-white; }

  width: 366px;
  height: 267px;

  display: flex; // to center content
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;

  cursor: pointer;

  &:hover {
    background-color: darken($color-green, 10%);
  }

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_quicktiles-center--below}") {
    margin-right: 10px;
    margin-left: 10px;
  }

  @include media("<#{$breakpoint_layout-quicktiles-button-like--below}") {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 91px;

    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
  }
}

.quick-tile--disabled {
  @import "../colors";
  $_disabled_color: $color-grey-70;
  background-color: $_disabled_color;

  cursor: not-allowed;

  &:hover {
    background-color: $_disabled_color;
  }

  .quick-tile__logo-holder {
    color: $_disabled_color;
  }


}

.quick-tile__logo-holder {
  @import "../colors";
  $_padding-top: 16px;

  border-radius: 100%;
  background-color: $color-white;
  width: 107px;
  height: 107px - $_padding-top;

  text-align: center;
  color: $color-blue;
  font-size: 75px;
  padding-top: 20px;
  flex-shrink: 0;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-quicktiles-button-like--below}") {
    $_padding-top: 10px;
    width: 49px;
    height: 49px - $_padding-top;
    padding-top: $_padding-top;
    font-size: 30px;
    margin-right: 24px;
    margin-left: 27px;
  }

  @include media("<#{$breakpoint_layout-quicktiles-text-in-vw--below}") {
    margin-right: 4vw;
    margin-left: 5vw;
  }
}

.quick-tile__title {
  font-size: 25px;
  font-weight: bold;
  margin-top: 19px;

  text-transform: uppercase;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-quicktiles-button-like--below}") {
    margin-top: 0;
    font-size: 22px;
  }

  @include media("<#{$breakpoint_layout-quicktiles-text-in-vw--below}") {
    margin-top: 0;
    font-size: 6vw;
  }
}

.quick-tile__subtitle {
  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_layout-quicktiles-button-like--below}") {
    font-size: 14px;
  }

  @include media("<#{$breakpoint_layout-quicktiles-text-in-vw--below}") {
    font-size: 4vw;
  }
}

.quick-tile__tiles-container {
  display: flex;
  flex-direction: column;
  align-items: center;

    // RESPONSIVE
    @import "~include-media/dist/include-media";
    @import "../breakpoints";

    @include media("<#{$breakpoint_layout-quicktiles-button-like--below}") {
      align-items: flex-start;
    }
}
