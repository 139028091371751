.action-bars {
  display: flex;

  > .action-bar {
    margin-right: 17px;

    &:last-of-type {
      margin-right: 0;
    }
  }


  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_action-bars-no-columns--below}") {
    display: block;
  }
}

.action-bars__grower {
  flex-grow: 1;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  min-width: 33%;

  padding: 7px 0;
  margin: 20px 0;

  @import "../colors";
  $_border-def: 1px solid $color-black;
  border-top: $_border-def;
  border-bottom: $_border-def;

  font-size: 14px;

  // RESPONSIVE
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  @include media("<#{$breakpoint_action-bars-no-columns--below}") {
    width: 100%;
  }

  &:empty:not(.action-bar__empty) {
    display: none;
  }
}

.action-bar__empty {
  @import "~include-media/dist/include-media";
  @import "../breakpoints";

  min-width: 0;

  @include media("<#{$breakpoint_action-bars-no-columns--below}") {
    display: none;
  }
}

.action-bar__no-margin {
  margin-bottom: 0;
}
